import { useEffect } from 'react';

export default function Section6() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <section className="section6">
      <div className="container text-center">
        <div className="content">
          <p className="section6--title">
            使い方はあなた次第。
            <br />
            バスケットボールを使って
            <br />
            誰も見たことが無い動画を作ろう！
          </p>
          <img src="/img/1_1.png" alt="icon" className="icon-image section6--img"></img>
          <div className="d-sm-flex text-center justify-content-center align-items-center wrapper">
            <p className="my-lg-auto me-lg-3 wrapper-text">
              今すぐ簡単！
              <br />
              無料ダウンロード！
            </p>
            <a href="https://apps.apple.com/jp/app/baller-%E3%83%90%E3%82%B9%E3%82%B1%E5%B0%82%E7%94%A8ai%E3%82%A8%E3%83%95%E3%82%A7%E3%82%AF%E3%83%88%E3%82%A2%E3%83%97%E3%83%AA/id1582782172">
              <img src="/img/App_Store_Badge_1.png" alt="app-store" className="wrapper-icon"></img>
            </a>
          </div>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-1796781700098375"
            data-ad-slot="2405710753"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </section>
  );
}
